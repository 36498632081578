@import '/apps/wt/webtools/sparta_build_server/tmp/EE81542B/artifacts/components/utilities/global/sparta-style-utility/4.9.4/scss/_sparta-project.scss';
$moduleNameSpace: dynamic-name-space;


[data-sparta-container] .#{$moduleNameSpace} {


  $smallish-only: '(min-width: 40.063em) and (max-width: 47.938em)';
  $smallish-down: '(max-width: 47.938em)';
  $smallish-up: '(min-width: 40.063em)';
  $smallish-breakpoint: rem-calc(767);

  $menu-button--color: $copy-gray-1;
  $menu-button--hover-color: darken($brown-tertiary, 20%);
  $menu-button--bg-color: darken($white, 5%);
  $menu-button--duration: 0.1s;

  $arrow-transition-duration: 0.3s;

  .hide-for-smallish-only {
    @media #{$smallish-only} {
      display: none !important;
    }
  }

  .header__default {
    position: relative;
  }

  .header__background {
    background-color: $white;
    width: 100%;

    @media #{$smallish-up} {
      display: inline-block;
    }
  }

  .header__row {
    align-items: center;
    display: flex;
    height: 40px;
    justify-content: space-between;
    position: relative;

    @media #{$smallish-up} {
      height: 70px;
    }
  }

  .header__default {
    display: flex;
    flex: 0 1 75%;
    padding-left: 6px;

    @media #{$smallish-only} {
      padding-left: 2px;
    }

    @media #{$smallish-up} {
      flex: 0 1 45%;
    }

    @media #{$medium-only} {
      padding-left: 3px;
    }
  }

  .header__logo {
    align-content: center;
    align-items: center;
    display: flex;
    justify-content: flex-start;
    width: 100%;

    a {
      padding-right: 10px;
      flex: 1;

      &:focus {
        outline: 1px $black dotted;
      }

      @media #{$medium-up} {
        flex: none;
      }
    }

    .header__logo-image {
      height: 100%;
      max-height: 27px;
      max-width: 220px;
      min-width: 155px;
      width: 100%;

      @media #{$smallish-up} {
        height: 29px;
        max-height: 29px;
        max-width: 300px;
        width: 300px;
      }
    }

    .header__logo-image--narrow {
      @media #{$medium-only} {
        width: 270px;
        padding-left: 5px;
      }
    }
  }

  .header__brand {
    color: $brown-secondary;
    font-size: 18px;
    padding-right: 10px;
  }

  .header__tagline {
    font-size: 14px;
    margin-left: 60px;
    white-space: nowrap; 

    @media #{$small-only} {
      display: none;
    }

    &.invisible {
      visibility: hidden;
      position: absolute;
    }

    &.small {
      font-size: 12px;
      margin-top: 8px;
    }
  }

  .header__children {
    align-items: center;
    display: flex;
    flex: 0 1 55%;
    justify-content: flex-end;
    padding-right: 10px;
  }

  .search-stub-child-container {
    @media #{$medium-up} {
      flex: 0 1 252px;
    }
  }

  .menu-button-wrap {
    align-items: center;
    display: flex;
    justify-content: center;
    white-space: nowrap; 

    @media #{$small-only} {
      font-size: 14px;
    }
  }

  .menu-button {
    color: $menu-button--color;
    text-decoration: none;
    padding: 5px;

    @media #{$large-up} {
      font-size: 18px;
    }

    &:focus,
    &:hover {
      color: $menu-button--hover-color;
      cursor: pointer;
    }

    &:focus {
      background-color: $menu-button--bg-color;
    }
  }

  .menu-button-arrow {
    transition: transform $arrow-transition-duration ease-out;
  }

  .arrow__container {
    position: relative;
  }

  .navigation__arrow {
    display: none; 
    position: absolute;
    right: 17px;
    top: 17px;
    z-index:1;

    @media #{$smallish-up} {
      top: 32px;
      right: 36px;
    }
  }

  .header {

    &--open {
      .menu-button-arrow {
        transform: rotate(-180deg);
      }

      .header__background--show-border {
        border-bottom: 1px solid $sb-warm-gray-light;
      }

      .navigation__arrow {
        display: block;
      }
    }
  }

  .header__secure {
    display: inline-block;
    position: relative;
    top: 3px;
    vertical-align: middle;

    a {
      color: $bank-brown-tertiary;
      display: inline-block;
      overflow: hidden;
      white-space: nowrap;
      @media #{$smallish-down} {
        width: 0;
      }
    }

    .lock-icon {
      margin-left: 6px;
      padding-left: 18px;
      position: relative;
      @media #{$smallish-only} {
        margin-left: 24px;
      }
      @media #{$medium-up} {
        margin-left: 22px;
      }

      &::before {
        background-repeat: no-repeat;
        content: "";
        display: block;
        height: 12px;
        left: 0;
        position: absolute;
        width: 10px;
      }
    }
  }

  .top-nav {
    .hide-for-smallish-only {
      @media #{$smallish-only} {
        display: none !important;
      }
    }

    .show-for-smallish-only {
      @media #{$smallish-only} {
        display: inherit !important;
      }
    }

    display: flex;
    justify-content: flex-end;

    .navigation__top {
      align-items: center;
      display: flex;
      justify-content: space-between;
      list-style: none;
      margin: 0;

      li {
        display: inline;
        font-size: 12px;
        padding-right: 10px;
        text-decoration: none;

        a {
          white-space: nowrap;
        }
      }
    }
  }
}



html.mda {
  [data-sparta-container] .#{$moduleNameSpace} {
    display: none;
  }
}
